body{
    height: 100%;
    width: 100%;
}

.line {
    width: 100%;
    height: 4rem;
    overflow: hidden;
    border: 1px solid black;
    padding: 0;
    margin-bottom: 16px;
  }

/* lineup class and keyframes */
.lineUp {
  animation: 2s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}



.right {
    background-color: white;
    animation: drawRightLine 1s linear 1s forwards;
    transform-origin: 0% 0% 0px;
    visibility: hidden;
  }
  
  @keyframes drawRightLine {
    0% {
          transform: scaleX(0);
          visibility: visible;
      }
      100% {
          transform: scaleX(1);
          visibility: visible;
      }	
      to {height: 300px;}
  }
